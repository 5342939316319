<template>
  <div class="q-ma-sm row rounded q-pa-sm bg-grey-2">
    <div ref="container" class="ProseMirror col"></div>

    <div class="q-px-sm border-left">
      <q-btn-dropdown
        v-if="keywords"
        color="dark"
        class="block full-width q-mb-sm"
        size="md"
        :label="$t('Keywords')"
        no-caps
        outline
      >
        <q-list>
          <q-item
            v-for="option in keywords"
            :key="option.id"
            clickable
            v-close-popup
            @click="insertKeyword(option)"
          >
            <q-item-section>
              <q-item-label>
                {{ option.name }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>

      <div v-if="!styleOptions || !styleOptions.onlyKeywords">
        <q-btn-dropdown
          color="dark"
          size="md"
          class="block full-width q-mb-sm"
          :label="$t('Insert')"
          no-caps
          outline
        >
          <q-list>
            <q-item
              v-for="option in insertButtons"
              :key="option.id"
              v-close-popup
              clickable
              @click="option.onClick"
              :disable="!!option.disabled"
            >
                {{ $t(option.label) }}
            </q-item>
          </q-list>
        </q-btn-dropdown>

        <q-btn-dropdown
          color="dark"
          size="md"
          class="block full-width q-mb-sm"
          :label="$t('Heading')"
          no-caps
          outline
        >
          <q-list>
            <q-item
              v-for="option in headButtons"
              :key="option.id"
              v-close-popup
              clickable
              @click="option.onClick"
              :disable="!!option.disabled"
            >
                {{ $t(option.label) }}
            </q-item>
          </q-list>
        </q-btn-dropdown>

        <q-btn-dropdown
          color="dark"
          size="md"
          class="block full-width q-mb-sm"
          :label="$t('Table')"
          :model-value="isOpenTDropdown"
          @update:model-value="handleToggleTableDropdown"
          no-caps
          outline
        >
          <q-list>
            <q-item
              v-for="option in tableButtons"
              :key="option.id"
              v-close-popup
              clickable
              @click="option.onClick"
              :disable="!!option.disabled"
            >
              {{ $t(option.label) }}
            </q-item>
          </q-list>
        </q-btn-dropdown>

        <q-btn-dropdown
          color="dark"
          class="block full-width q-mb-sm"
          size="md"
          :label="$t('Transform')"
          no-caps
          outline
        >
          <q-list>
            <q-item
              v-for="option in transformButtons"
              :key="option.id"
              v-close-popup
              clickable
              @click="option.onClick"
              :disable="!!option.disabled"
            >
              {{ $t(option.label) }}
            </q-item>
          </q-list>
        </q-btn-dropdown>

        <q-btn
          :label="$t('Styles')"
          :disable="isDisabledStyle"
          size="md"
          class="block full-width q-mb-xs"
          no-caps
          outline
          align="left"
          color="dark"
          @click="toggleStyleModal"
        />

        <q-btn
          v-for="btn in contextButtons"
          :key="btn.id"
          :label="btn.label"
          :disable="!!btn.disabled"
          size="md"
          class="block full-width q-mb-xs"
          no-caps
          outline
          align="left"
          color="dark"
          @click="btn.onClick"
        />
      </div>
    </div>

    <q-dialog v-model="isOpenStyleModal" class="modal-md">
      <q-card>
        <q-card-section class="row items-center">
          <div class="text-h6 text-center">
            {{ $t('Set styles') }}
          </div>

          <q-space />

          <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="toggleStyleModal"
          />
        </q-card-section>

        <q-card-section>
          <q-input
            v-model="styles"
            standout="bg-teal text-white"
            :label="$t('Styles')"
          />
        </q-card-section>

        <q-card-section class="text-center">
          <q-btn
            color="dark"
            text-color="white"
            class="q-mr-sm"
            :label="$t('Discard')"
            @click="toggleStyleModal"
          />

          <q-btn
            color="light-blue-9"
            text-color="white"
            :label="$t('Save')"
            @click="saveStyles"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Plugins
import { Editor } from '../../../../packages/editor'

export default {
  name: 'MaterialEditor',
  props: {
    styleOptions: {
      type: Object,
      default () {
        return {}
      }
    },
    keywords: {
      type: Array,
      default () {
        return null
      }
    },
    modelValue: {
      type: [String, Array],
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      editor: null,
      styles: '',
      isOpenTDropdown: false,
      isDisabledStyle: true,
      isOpenStyleModal: false
    }
  },
  computed: {
    transformButtons () {
      return  [
        {
          id: 'transformP',
          label: this.$t('Paragraph'),
          onClick: () => {
            return this.editor.plugins.transform.to({ tag: 'p' })
          }
        },
        {
          id: 'transformH1',
          label: this.$t('H1'),
          onClick: () => {
            return this.editor.plugins.transform.to({ tag: 'h1' })
          }
        },
        {
          id: 'transformH2',
          label: this.$t('H2'),
          onClick: () => {
            return this.editor.plugins.transform.to({ tag: 'h2' })
          }
        },
        {
          id: 'transformH3',
          label: this.$t('H3'),
          onClick: () => {
            return this.editor.plugins.transform.to({ tag: 'h3' })
          }
        },
        {
          id: 'transformH4',
          label: this.$t('H4'),
          onClick: () => {
            return this.editor.plugins.transform.to({ tag: 'h4' })
          }
        },
        {
          id: 'transformH5',
          label: this.$t('H5'),
          onClick: () => {
            return this.editor.plugins.transform.to({ tag: 'h5' })
          }
        },
        {
          id: 'transformH6',
          label: this.$t('H6'),
          onClick: () => {
            return this.editor.plugins.transform.to({ tag: 'h6' })
          }
        }
      ]
    },
    contextButtons () {
      return [
        {
          id: 'bold',
          label: this.$t('Bold'),
          onClick: () => {
            return this.editor.plugins.transform.toggle({ style: 'bold' })
          }
        },
        {
          id: 'italic',
          label: this.$t('Italic'),
          onClick: () => {
            return this.editor.plugins.transform.toggle({ style: 'italic' })
          }
        },
        {
          id: 'strike',
          label: this.$t('Strike'),
          onClick: () => {
            return this.editor.plugins.transform.toggle({ style: 'strike' })
          }
        },
        {
          id: 'highlight',
          label: this.$t('Highlight'),
          onClick: () => {
            return this.editor.plugins.transform.toggle({ style: 'highlight' })
          }
        },
        {
          id: 'left',
          label: this.$t('Left'),
          onClick: () => {
            return this.editor.plugins.transform.style({ textAlign: 'left' })
          }
        },
        {
          id: 'center',
          label: this.$t('Center'),
          onClick: () => {
            return this.editor.plugins.transform.style({ textAlign: 'center' })
          }
        },
        {
          id: 'right',
          label: this.$t('Right'),
          onClick: () => {
            return this.editor.plugins.transform.style({ textAlign: 'right' })
          }
        }
      ]
    },
    headButtons () {
      return [
        {
          id: 'insertH1',
          label: this.$t('H1'),
          onClick: () => {
            return this.editor.plugins.h.insert({ level: 1 })
          }
        },
        {
          id: 'insertH2',
          label: this.$t('H2'),
          onClick: () => {
            return this.editor.plugins.h.insert({ level: 2 })
          }
        },
        {
          id: 'insertH3',
          label: this.$t('H3'),
          onClick: () => {
            return this.editor.plugins.h.insert({ level: 3 })
          }
        },
        {
          id: 'insertH4',
          label: this.$t('H4'),
          onClick: () => {
            return this.editor.plugins.h.insert({ level: 4 })
          }
        },
        {
          id: 'insertH5',
          label: this.$t('H5'),
          onClick: () => {
            return this.editor.plugins.h.insert({ level: 5 })
          }
        },
        {
          id: 'insertH6',
          label: this.$t('H6'),
          onClick: () => {
            return this.editor.plugins.h.insert({ level: 6 })
          }
        }
      ]
    },
    insertButtons () {
      return [
        {
          id: 'insertP',
          label: this.$t('Paragraph'),
          onClick: () => {
            return this.editor.plugins.p.insert()
          }
        },
        {
          id: 'insertBullet',
          label: this.$t('Bullet list'),
          onClick: () => {
            return this.editor.plugins.list.insert({ type: 'ul' })
          }
        },
        {
          id: 'insertOrder',
          label: this.$t('Ordered list'),
          onClick: () => {
            return this.editor.plugins.list.insert({ type: 'ol' })
          }
        },
        {
          id: 'insertHorizontalRule',
          label: this.$t('Horizontal rule'),
          onClick: () => {
            return this.editor.plugins.hr.insert()
          }
        },
        {
          id: 'insertHardBreak',
          label: this.$t('Hard break'),
          onClick: () => {
            return this.editor.plugins.br.insert()
          }
        }
      ]
    },
    tableButtons () {
      // Force rerender options to can work disabled option
      if (!this.isOpenTDropdown) {
        return []
      }

      return [
        {
          id: 'insertTable',
          label: this.$t('Insert'),
          onClick: () => {
            return this.editor.plugins.table.insert({ rows: 3, cols: 3, withHeaderRow: true })
          }
        },
        {
          id: 'addColumnBefore',
          label: this.$t('Add column before'),
          onClick: () => {
            return this.editor.plugins.table.addColumnBefore()
          },
          disabled: !this.editor.plugins.table.can.addColumnBefore()
        },
        {
          id: 'addColumnAfter',
          label: this.$t('Add column after'),
          onClick: () => {
            return this.editor.plugins.table.addColumnAfter()
          },
          disabled: !this.editor.plugins.table.can.addColumnAfter()
        },
        {
          id: 'deleteColumn',
          label: this.$t('Delete column'),
          onClick: () => {
            return this.editor.plugins.table.deleteColumn()
          },
          disabled: !this.editor.plugins.table.can.deleteColumn()
        },
        {
          id: 'addRowBefore',
          label: this.$t('Add row before'),
          onClick: () => {
            return this.editor.plugins.table.addRowBefore()
          },
          disabled: !this.editor.plugins.table.can.addRowBefore()
        },
        {
          id: 'addRowAfter',
          label: this.$t('Add row after'),
          onClick: () => {
            return this.editor.plugins.table.addRowAfter()
          },
          disabled: !this.editor.plugins.table.can.addRowAfter()
        },
        {
          id: 'deleteRow',
          label: this.$t('Delete row'),
          onClick: () => {
            return this.editor.plugins.table.deleteRow()
          },
          disabled: !this.editor.plugins.table.can.deleteRow()
        },
        {
          id: 'deleteTable',
          label: this.$t('Delete table'),
          onClick: () => {
            return this.editor.plugins.table.deleteTable()
          },
          disabled: !this.editor.plugins.table.can.deleteTable()
        }
      ]
    }
  },
  mounted() {
    this.editor = Editor.create({
      parent: this.$refs.container,
      content: this.modelValue,
      onUpdate: (html) => {
        this.$emit('update:model-value', html)
        this.isDisabledStyle = !this.editor.isFocused
      },
      onError: (error) => {
        this.addErrorNotification(error)
      }
    })
  },
  beforeUnmount() {
    this.editor.destroy()
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    insertKeyword (keyword) {
      const data = {
        content: `{{${keyword.id}}}`,
        props: {
          style: 'background: orange;',
          'data-id': keyword.id
        }
      }

      this.editor.plugins.span.insert(data)
    },
    saveStyles () {
      this.editor.replaceStyle({ styles: this.styles })
      this.toggleStyleModal()
    },
    toggleStyleModal () {
      this.isOpenStyleModal = !this.isOpenStyleModal

      if (this.isOpenTDropdown) {
        this.style = this.editor.getStyles()
      }
    },
    handleToggleTableDropdown () {
      this.isOpenTDropdown = !this.isOpenTDropdown
    }
  }
}
</script>
